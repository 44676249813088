import React from 'react'
import SvgIcon from './SvgIcon'
import caution from '../assets/images/caution.svg'

export default function Modal ({ visible, text, onClose }) {
  if (!visible) return ''

  function closeModal () {
    onClose(false)
  }

  return (
    <div className="modal">
      <div className="modal-background" onClick={closeModal} />
      <div className="modal-box">
        <SvgIcon src={caution} />
        <p>{ text }</p>
      </div>
    </div>
  )
}