import React from 'react'
import { useState, useEffect } from 'react'
import axios from 'axios'
import dayjs from 'dayjs'

// Common Component
import SvgIcon from "./components/SvgIcon"

// Asset
import logo from './assets/images/logo-80proof.svg'
import arrow from './assets/images/angle-arrow.svg'
import FormItem from "./components/FormItem"

// Form
import Input from 'rc-input'
import Select, { Option } from 'rc-select';
import { DatePickerInput } from 'rc-datepicker';
import TimePicker from 'rc-time-picker';
import 'rc-datepicker/lib/style.css';
import 'rc-time-picker/assets/index.css';
import Modal from './components/Modal'
import Spinner from './components/Spinner'

// Dummy Data
const todayEvents = [{
  image: 'https://images.unsplash.com/photo-1516873240891-4bf014598ab4',
  artist: 'DJ Monx',
  name: 'Local Resident DJ',
  time: '19.00-20.00'
}, {
  image: 'https://images.unsplash.com/photo-1516873240891-4bf014598ab4',
  artist: 'DJ Monx',
  name: 'Local Resident DJ',
  time: '19.00-20.00'
}, {
  image: 'https://images.unsplash.com/photo-1516873240891-4bf014598ab4',
  artist: 'DJ Monx',
  name: 'Local Resident DJ',
  time: '19.00-20.00'
}, {
  image: 'https://images.unsplash.com/photo-1516873240891-4bf014598ab4',
  artist: 'DJ Monx',
  name: 'Local Resident DJ',
  time: '19.00-20.00'
}]

export default function BookingForm() {
  // query params 
  const [token, setToken] = useState('')
  const [tableName, setTableName] = useState(null)
  const [tableId, setTableId] = useState(null) // for api as request id
  const [tableStatus, setTableStatus] = useState('')
  const [enumMaxPerson, setEnumMaxPerson] = useState([])
  const [minCharge, setMinCharge] = useState(0)
  const [modalVisible, setModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [modalText, setModalText] = useState('')
  const [eventList, setEventList] = useState([])
  const [currEnv, setCurrEnv] = useState('')
  
  // form state
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [formBookingName, setFormBookingName] = useState({
    value: '',
    error: '',
  })
  const [formBookingPhone, setFormBookingPhone] = useState({
    value: '',
    error: '',
  })
  const [formBookingDate, setFormBookingDate] = useState({
    value: '',
    error: '',
  })
  const [formBookingTime, setFormBookingTime] = useState({
    value: '',
    error: '',
  })
  const [formBookingPerson, setFormBookingPerson] = useState({
    value: '',
    error: '',
  })

  function getApiUrl (uri = '') {
    console.log('arya env', currEnv)
    const nodeEnv = 'development'
    let path = 'https://80proof-api.govirtual.id'
    
    if (nodeEnv === 'development') {
      path = 'https://80proof-dev.govirtual.id'
    }
    if (nodeEnv === 'staging') {
      path = 'https://80proof-stg.govirtual.id'
    }

    return `${path}${uri}`
  }

  function renderTodayEvent (events=[]) {
    const data = events.length ? events : []

    if (!events?.length) return false

    return (
      <div className="booking-events-wrap">
        <div className="title">Today Event</div>
        <div className="booking-events-box">
          {
            data.map((item, index) => (
              <div className="booking-event" key={index}>
                <img src={item.image} alt="event-image" className="image" />
                <div className="content-wrap">
                  <div className="text">{item.name}</div>
                  <div className="text">{item.artist}</div>
                  <div className="time">{item.time}</div>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    )
  }

  function searchParams (params = '') {
    const urlParams = new URLSearchParams(window.location.search)
    return urlParams.get(params)
  }

  useEffect(() => {
    setToken(searchParams('token'))
    setTableId(searchParams('tableId'))
    setFormBookingDate({ ...formBookingDate, value: dayjs() })
    setCurrEnv(process.env.NODE_ENV || '')

    if (tableId) {
      fetchAvailability()
    }
  }, [tableId])

  function handleBookingName (param) {
    const data = { ...formBookingName }
    data.value = param.target.value
    setFormBookingName(data)
  }
  function handleBookingPhone (param) {
    const data = { ...formBookingPhone }
    data.value = param.target.value
    setFormBookingPhone(data)
  }
  function handleBookingDate (date) {
    // const data = { ...formBookingDate }
    // data.value = date
    // setFormBookingDate(data)
    fetchAvailability(date)
  }
  function handleBookingPerson (val) {
    const data = { ...formBookingPerson }
    data.value = val
    setFormBookingPerson(data)
  }
  function handleBookingTime (val) {
    const data = { ...formBookingTime }
    data.value = val.format('HH:mm')
    setFormBookingTime(data)
  }

  function validatorBookingName (val) {
    const data = { ...formBookingName }
    if (!val) {
      data.error = 'this field cannot be empty'
      setFormBookingName(data)
      return false
    }

    data.error = ''
    setFormBookingName(data)
    return true
  }
  function validatorBookingPhone (val) {
    const data = { ...formBookingPhone }
    if (!val) {
      data.error = 'this field cannot be empty'
      setFormBookingPhone(data)
      return false
    }

    if (!(/^\d+$/.test(val))) {
      data.error = 'this field contain only number'
      setFormBookingPhone(data)
      return false
    }

    if (!(/^8\d*$/.test(val))) {
      data.error = 'Please use format phone'
      setFormBookingPhone(data)
      return false
    }

    data.error = ''
    setFormBookingPhone(data)
    return true
  }
  function validatorBookingDate (val) {
    const data = { ...formBookingDate }
    if (!val) {
      data.error = 'this field cannot be empty'
      setFormBookingDate(data)
      return false
    }

    if (tableStatus !== 'AVAILABLE') {
      data.error = 'The table is already taken, please choose another table'
      setFormBookingDate(data)
      return false
    }

    data.error = ''
    setFormBookingDate(data)
    return true
  }

  function cacheDataBooking (data) {
    const bookingData = localStorage.getItem('bookingData')

    if (bookingData) {
      localStorage.removeItem('bookingData')
    }
    localStorage.setItem('bookingData', JSON.stringify(data))
  }
  
  function handleSubmitBooking () {
    setFormSubmitted(true)
    let requestApi = {}
    const isNameSafe = validatorBookingName(formBookingName.value)
    const isPhoneSafe = validatorBookingPhone(formBookingPhone.value)
    const isDateSafe = validatorBookingDate(formBookingDate.value)

    const formValidation = token ? isDateSafe : (isNameSafe && isPhoneSafe && isDateSafe)

    if ( formValidation ) {
      requestApi = {
        tableId: tableId ? parseInt(tableId) : tableId,
        date: formBookingDate.value ? dayjs(formBookingDate.value).format('YYYY-MM-DD') : formBookingDate.value,
        arrivalTime: formBookingTime.value,
        pax: formBookingPerson.value ? parseInt(formBookingPerson.value) : formBookingPerson.value,
      }
      
      if (!token) {
        requestApi.name = formBookingName.value
        requestApi.mobileNo = `0${formBookingPhone.value}`
      }

      fetchPayment(requestApi)
    }
  }

  function openModal (visible = false, text) {
    setModalVisible(visible)
    setModalText(text)
  }
  function handleModalClose (value = false) {
    setModalVisible(value)
  }

  function formatCurrency (currency = 0) {
    const price = currency.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
    return `Rp ${price}`;
  }

  function fetchPayment (requested) {

    const config = {
      method: 'post',
      url: getApiUrl('/payment'),
      data: requested
    }

    if (token) {
      config.headers = {
        'Authorization': `Bearer ${token}`,
      }
    }

    setLoading(true)
    axios(config).then((response = {}) => response.data ).then((res = {}) => {
      setLoading(false)

      if (res.isSuccess) {
        const data = (res.result && res.result.data) || {}
        cacheDataBooking({
          bookingName: requested.name,
          bookingPhone: requested.mobileNo,
          bookingDate: requested.date,
          bookingTable: tableName,
          bookingTime: requested.arrivalTime,
          bookingPerson: requested.pax,
        })

        window.location.href = data.paymentUrl
      }
    }).catch((err) => {
      const errData = err?.response?.data || {}
      setLoading(false)
      openModal(true, errData?.message || '')
    })
  }

  function fetchEvent (date) {
    const url = getApiUrl(`/event/date/${date}`)
    axios({
      method: 'get',
      url,
    }).then((response = {}) => response.data ).then((res = {}) => {
      if (res.isSuccess) {
        const data = (res.result && res.result.data) || []
        const events = data?.length ? data.map((item) => {
          return {
            image: item.imageUrl,
            name: item.title,
            time: item.startTime,
          }
        }) : []

        setEventList(events)
      }
    }).catch((err) => {
      const errData = err?.response?.data || {}
      openModal(true, errData?.message || '')
    })

  }

  function fetchAvailability (date = '') {
    const currentDate = date ? dayjs(date).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD')
    const url = getApiUrl(`/table/${tableId}?date=${currentDate}`)
    axios({
      method: 'get',
      url,
    }).then((response = {}) => response.data ).then((res = {}) => {
      if (res.isSuccess) {
        const data = res?.result?.data || {}
        const name = data?.shortName || ''
        const dateError = data?.status !== 'AVAILABLE' ? 'The table is already taken, please choose another table' : ''

        const enumPax = []
        for (let i = 1; i <= data.capacity; i++) {
          enumPax.push(i)
        }

        setEnumMaxPerson(enumPax)
        setTableName(name)
        setTableStatus(data?.status)
        fetchEvent(currentDate)
        setMinCharge(data?.minCharge || 0)

        if (date) {
          setFormBookingDate({ value: date, error: dateError })
        }
      }
      
    }).catch((err) => {
      const errData = err?.response?.data || {}
      openModal(true, errData?.message || '')
    })
  }

  return (
    <div className="main">
      <div className="container">
        <div className="booking-page">
          <Modal
            visible={modalVisible}
            text={modalText}
            onClose={handleModalClose}
          />
          <Spinner
            visible={loading}
          />

          <div className="header">
            <button className="btn-back"><SvgIcon src={arrow} color="white" /></button>
            <SvgIcon src={logo} color="white" />
          </div>
          <div className="table-number">
            <span>{ tableName }</span>
          </div>
          <div className="form">
            { !token && (
              <>
                <FormItem
                  label="Name"
                  isRequired={formSubmitted && !formBookingName.value}
                  errorMessage={formBookingName.error}
                >
                  <Input
                    placeholder="please enter your name"
                    onChange={handleBookingName}
                  />
                </FormItem>
                <FormItem
                  label="Phone Number"
                  isRequired={formSubmitted && !formBookingPhone.value}
                  errorMessage={formBookingPhone.error}
                >
                  <Input
                    addonBefore={'+62 | '}
                    placeholder="8123456"
                    onChange={handleBookingPhone}
                  />
                </FormItem>
              </>
            ) }
            <FormItem
              label="Select Date"
              isRequired={formSubmitted && !formBookingDate.value}
              errorMessage={formBookingDate.error}
              isSelect={true}
            >
              <DatePickerInput
                displayFormat="D MMMM YYYY"
                returnFormat="DD/MMMM/YYYY"
                value={dayjs(formBookingDate.value).format('DD/MMMM/YYYY')}
                onChange={handleBookingDate}
              />
            </FormItem>
            <FormItem
              label="estimated arrival time (optional)"
              isSelect={true}
            >
              <TimePicker
                showSecond={false}
                use12Hours={true}
                popupClassName="rc-book-time"
                onChange={handleBookingTime}
              />
            </FormItem>
            <FormItem
              label="How many Person(s) (optional)"
              isSelect={true}
            >
              <Select onChange={handleBookingPerson}>
                {
                  enumMaxPerson.map((item, index) => (
                    <Option key={index} value={item}>{item}</Option>
                  ))
                }
              </Select>
            </FormItem>
            {
              !!minCharge && (
                <div className="min-charge">Minimum Charge { formatCurrency(minCharge) }</div>
              )
            }
          </div>

          { renderTodayEvent(eventList) }

          <div className="booking-bottom-page">
            <div className="booking-info">
              In the event of any changes or if you need to cancel your reservation, please notify us as soon as possible by calling 0812-6723-8123. We understand that plans may change, and we appreciate your consideration in this regard.
            </div>

            <div className="button-wrap">
              <button
                className="btn btn-aqua-glow"
                onClick={handleSubmitBooking}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}