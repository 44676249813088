import React from 'react'
import { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import axios from 'axios'

// Common Component
import SvgIcon from "./components/SvgIcon"
import LottieBox from './components/LottieBox'

// Asset
import logo from './assets/images/logo-80proof.svg'
import successLottie from './assets/lottie/checkmark_green.json'
import fireWorksLottie from './assets/lottie/fire_works.json'

export default function BookingSuccess() {
  const [transition, setTransition] = useState(false)
  const [cacheBooking, setCacheBooking] = useState(false)

  useEffect(() => {
    setTransition(true)

    const bookingData = localStorage.getItem('bookingData')
    if (bookingData) {
      setCacheBooking(JSON.parse(bookingData))
    } else {
      // call API?
    }
  }, [])

  function infoItem (label, value) {
    return (
      <div className="info-wrap">
        <div className="text">{ label }</div>
        <div className="text">{ value }</div>
      </div>
    )
  }

  function handleGotoHome () {
    window.location.href = 'redirect_to_deeplink_url' // for back to app
  }

  return (
    <div className="main">
      <div className="container">
        <div className="booking-page success">
          <div className="header">
            <SvgIcon src={logo} color="white" />
          </div>
          

          <div className="success-header-wrap">
            <div className="fireworks-lottie">
              <LottieBox lottie={fireWorksLottie} opt={{ loop: true }} />
            </div>
            <div className="lottie">
              <LottieBox lottie={successLottie} />
            </div>
          </div>
          <div className={`transition${transition ? ' active' : ''}`}>
            <div className="success-header-wrap">
              <div className="text">your reservation <br /> is successfully made</div>
            </div>
            <div className="success-info-wrap">
              { cacheBooking.bookingName && infoItem('Name', cacheBooking.bookingName) }
              { cacheBooking.bookingPhone && infoItem('Phone Number', cacheBooking.bookingPhone) }
              { infoItem('Date', dayjs(cacheBooking.bookingDate).format('DD MMMM YYYY')) }
              { infoItem('table number', cacheBooking.bookingTable) }
              { infoItem('Time', cacheBooking.bookingTime) }
              { infoItem('how many person', `${cacheBooking.bookingPerson} Pax`) }
            </div>

          </div>
          <div className="success-button-wrap">
            <button
              className="btn btn-aqua-glow"
              onClick={handleGotoHome}
            >
              Okay
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}