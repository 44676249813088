import React, { useEffect, useRef } from 'react'
import Lottie from 'lottie-web'

export default function LottieBox ({ lottie, opt = {} }) {
  const lottieDom = useRef(null)
  const lottieHandling = useRef(null)

  useEffect(() => {
    setTimeout(() => {
      lottieHandling.current = Lottie.loadAnimation({
        container: lottieDom.current,
        renderer: 'svg',
        loop: false,
        autoplay: true,
        animationData: lottie,
        ...opt,
      })
    })

    return () => (
      lottieHandling?.current?.destroy()
    )
  }, [lottie])

  return (
    <div
      className="lottie-div" ref={lottieDom}
    ></div>
  );
}