import React from "react";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import BookingSuccess from "./BookingSuccess";
import BookingForm from "./BookingForm";

import './style/index.scss';

export default function App () {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <BookingForm />,
    },
    {
      path: "/success",
      element: <BookingSuccess />,
    },
  ]);

  return (
    <RouterProvider router={router} />
  );
};
