import React from 'react'

import SvgIcon from "./SvgIcon";
import arrow from '../assets/images/angle-arrow.svg'

export default function FormItem ({ label = '', isSelect = false, isRequired = false, errorMessage = '', children }) {
  return (
    <div className={`form-item ${( errorMessage ) ? 'error' : ''}`}>
      <div className={`form-item-inner ${isSelect ? 'hasSelect' : ''}`}>
        <div className="label">{ label }</div>
        <div className="form-input">
          { children }
        </div>
        { isSelect && (
          <div className="arrow">
            <SvgIcon src={arrow} color="#D9D9D9" />
          </div>
        ) }
      </div>
      { errorMessage && (
        <div className="error-message">{ errorMessage }</div>
      ) }
    </div>
  )
}